import * as React from "react";
import { PortfolioItems } from "./../data";
import { PageHeader } from "../components/page-header";
import { SolutionType } from "../data";
import { Helmet } from "react-helmet";

const importAll = (r: any) => {
  const imgs: { [x: string]: any } = {};
  r.keys().map((item: string) => {
    imgs[item.replace("./", "")] = r(item);
  });
  return imgs;
};

const images = importAll(require.context("../images/portfolio", false));

interface IState {
  filterByType: string;
}

export default class PortfolioPage extends React.Component<{}, IState> {
  constructor(props: {}, public state: IState) {
    super(props);

    this.state = {
      filterByType: "All"
    };
  }

  get filters() {
    const { filterByType } = this.state;
    const options = ["All"].concat(Object.values(SolutionType));
    return options.map((f: string) => {
      return (
        <div
          key={f}
          className={`filter ${f === filterByType ? "active" : ""}`}
          onClick={() => {
            this.setState({ filterByType: f });
          }}
        >
          {f}
        </div>
      );
    });
  }

  get items() {
    const { filterByType } = this.state;
    const filtered =
      filterByType === "All"
        ? PortfolioItems
        : PortfolioItems.filter(
            item =>
              item.type.find(t => t.valueOf() === filterByType) !== undefined
          );
    return filtered.map(item => (
      <div
        className="col-lg-6 col-xl-6 col-md-6 portfolio-detail"
        key={item.title}
      >
        <a
          className="portfolio-box m-0"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: 350,
            backgroundSize: "cover",
            backgroundImage: `url(${images[item.image]})`
          }}
          href={`/solutions/${item.title.replace(/ /g, "-")}`}
        >
          <div
            className="portfolio-box-caption"
            style={{ width: "100%", height: 350, maxWidth: "100%", margin: 0 }}
          >
            <div className="portfolio-box-caption-content">
              <div className="project-category text-faded">
                {item.type.join(", ")}
              </div>
              <div className="project-name">{item.title}</div>
            </div>
          </div>
        </a>
      </div>
    ));
  }

  render() {
    return (
      <div className="App-page">
        <Helmet>
          <title>
            Olga Carpenter - Sample of Informatics Solutions delivered
          </title>
          <meta
            name="description"
            content="I combine knowledge of chemistry, biology, statistics and computer
              science to create effective technical solutions for professionals
              in the life science and healthcare industries that save time and
              add value"
          />
        </Helmet>
        <PageHeader title="Solutions Sample" />
        <div className="container-fluid p-0">
          <div className="container d-none d-sm-block">
            <div className="row justify-content-md-center">{this.filters}</div>
          </div>
          <div className="row no-gutters popup-gallery">{this.items}</div>
        </div>
      </div>
    );
  }
}
