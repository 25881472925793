import * as React from "react";

export interface IProps {}

export default class Headline extends React.Component<IProps, any> {
  public render() {
    return (
      <section className="App-headline">
        <div className="headline text-center">
          <div className="col-lg-12 mx-auto">
            <h1 style={{ letterSpacing: 3 }}>
              <strong>Scientist. Informatician. Developer.</strong>
            </h1>
            <hr />
          </div>
          <div className="col-lg-8 mx-auto">
            <p className="text-faded mb-5">
              Rapid, technical solutions for life sciences and healthcare
              industries.
            </p>
            <a href="/portfolio" className="btn btn-primary btn-xl">
              View My Work
            </a>
          </div>
        </div>
      </section>
    );
  }
}
