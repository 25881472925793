import * as React from "react";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import Button from "reactstrap/lib/Button";
import BlockUi from "react-block-ui";

export interface IContactFormState {
  name: string;
  email: string;
  projectDescription: string;
  message: string;
  error: string;
  sending: boolean;
}

export interface IContactFormProps {
  instruction?: string;
}

export default class ContactForm extends React.Component<
  IContactFormProps,
  IContactFormState
> {
  constructor(props: IContactFormProps, public state: IContactFormState) {
    super(props);

    this.state = {
      name: "",
      email: "",
      projectDescription: "",
      message: "",
      error: "",
      sending: false
    };
  }

  private get isFormValid() {
    const { name, email, projectDescription } = this.state;
    return name !== "" && email !== "" && projectDescription !== "";
  }

  private sendEmailNotification = () => {
    const { name, email, projectDescription } = this.state;
    this.setState({ sending: true, error: "", message: "" });
    const url =
      document.domain === "127.0.0.1" || document.domain === "localhost"
        ? `http://localhost:5000/olga-carpenter/us-central1/emailNotification`
        : `https://us-central1-olga-carpenter.cloudfunctions.net/emailNotification`;

    const data = { name, email, msg: projectDescription };
    // url += `?name=${name}&email=${email}&msg=${projectDescription}`;

    const myRequest = new Request(url, {
      method: "POST",
      body: JSON.stringify(data)
    });

    fetch(myRequest)
      .then(response => {
        const sending = false;
        if (!response.ok) {
          this.setState({ error: "Oops, something went wrong! :-(", sending });
          throw new Error("HTTP error, status = " + response.status);
        }
        this.setState({
          name: "",
          email: "",
          projectDescription: "",
          message: "Thank you for your message!",
          error: "",
          sending
        });
        return response.json();
      })
      .catch(response => {
        this.setState({
          error: "Oops, something went wrong! :-(",
          sending: false
        });
      });
  };

  get form() {
    const { instruction } = this.props;
    const { name, email, projectDescription: message } = this.state;
    return (
      <BlockUi
        tag="div"
        blocking={this.state.sending}
        style={{ width: "100%" }}
      >
        <p className="lead">{instruction}</p>
        <Form className="container">
          <FormGroup row>
            <Label for="oc-name" sm={2}>
              Name
            </Label>
            <Col sm={10}>
              <Input
                value={name}
                type="text"
                name="name"
                id="oc-name"
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="oc-email" sm={2}>
              Email
            </Label>
            <Col sm={10}>
              <Input
                value={email}
                type="email"
                name="email"
                id="oc-email"
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="oc-Text" sm={2}>
              Project Description
            </Label>
            <Col sm={10}>
              <Input
                value={message}
                type="textarea"
                name="text"
                id="oc-Text"
                // rows={7}
                onChange={e => {
                  this.setState({ projectDescription: e.target.value });
                }}
                required
              />
            </Col>
          </FormGroup>

          <FormGroup check row>
            <Col
              sm={{ size: 10, offset: 2 }}
              className="text-center text-md-left"
            >
              <Button
                disabled={!this.isFormValid}
                color="primary"
                size="xl"
                onClick={() => this.sendEmailNotification()}
              >
                Send Message
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </BlockUi>
    );
  }

  get result() {
    const { error, message } = this.state;
    if (!error && !message) return null;
    return error ? (
      <div className="text-danger form-feedback">{error}</div>
    ) : (
      <div className="text-primary form-feedback">{message}</div>
    );
  }

  public render() {
    return (
      <React.Fragment>
        {this.result}
        {this.form}
      </React.Fragment>
    );
  }
}
