import * as React from "react";
import { PortfolioSection } from "./../components/portfolio-section";
import { ServicesSection } from "./../components/services-section";
import { ContactSection } from "./../components/contact-section";
import { AboutSection } from "./../components/about-section";
import Headline from "../components/headline";

export interface IHomeProps {}

export function HomePage(props: IHomeProps) {
  return (
    <div>
      <Headline />
      <AboutSection />
      <ServicesSection />
      <PortfolioSection />
      <section className="bg-dark text-white">
        <div className="container text-center">
          <h2 className="mb-4">Check out more of my projects!</h2>
          <a className="btn btn-light btn-xl sr-button" href="/portfolio">
            View More
          </a>
        </div>
      </section>
      <ContactSection />
    </div>
  );
}
