import * as React from "react";
import img from "./../images/laptop-analytics.jpg";
import { PortfolioItems } from "../data";

const importAll = (r: any) => {
  const imgs: { [x: string]: any } = {};
  r.keys().map((item: string) => {
    imgs[item.replace("./", "")] = r(item);
  });
  return imgs;
};

const images = importAll(require.context("../images/portfolio", false));

export const PortfolioSection = (props: {}) => {
  const items = PortfolioItems.map(item => (
    <div className="col-lg-4 col-sm-6" key={item.title}>
      <a
        className="portfolio-box"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: 350,
          backgroundSize: "cover",
          backgroundImage: `url(${images[item.image]})`
        }}
        href={`/solutions/${item.title.replace(/ /g, "-")}`}
      >
        <div
          className="portfolio-box-caption"
          style={{ width: "100%", height: 350, maxWidth: "100%", margin: 0 }}
        >
          <div className="portfolio-box-caption-content">
            <div className="project-name">{item.title}</div>
            <div className="project-category text-faded">
              {item.type.join(", ")}
            </div>
          </div>
        </div>
      </a>
    </div>
  ));

  return (
    <section className="p-0" id="portfolio">
      <div className="container-fluid p-0">
        <div className="row no-gutters popup-gallery">{items}</div>
      </div>
    </section>
  );
};
