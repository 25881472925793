import React from "react";
import logo from "./../images/olga-light.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Footer extends React.Component<{}> {
  render() {
    return (
      <footer className="App-footer py-2">
        <div className="container row mx-auto">
          <div className="footer-item col-md-6 my-2 text-center text-md-left">
            <img src={logo} alt="Logo" className="App-logo img-fluid" />
          </div>
          {/* <div className="footer-item">
            <FontAwesomeIcon
              icon="linkedin-in"
              className="fab m-1 text-white"
            />
          </div> */}
          <div className="footer-item col-md-6 my-2 text-center text-md-right d-flex justify-content-center justify-content-md-end align-items-center">
            © Olga Carpenter - All Rights Reserved.
          </div>
        </div>
      </footer>
    );
  }
}
