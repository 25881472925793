import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageHeader } from "../components/page-header";
import { Helmet } from "react-helmet";

export const ServicesPage = () => {
  return (
    <div className="App-page">
      <Helmet>
        <title>
          Olga Carpenter - Informatics and Development Services for Life
          Sciences
        </title>
        <meta
          name="description"
          content="I combine knowledge of chemistry, biology, statistics and computer
              science to create effective technical solutions for professionals
              in the life science and healthcare industries that save time and
              add value"
        />
      </Helmet>
      <PageHeader title="How I can help you" />
      <div className="container page-content">
        <div className="row">
          <div className="col-lg-6 col-md-6 my-5">
            <h3 className="mb-3">
              <FontAwesomeIcon
                icon="code"
                className="fas fa-2x mr-4 text-primary"
              />
              Development
            </h3>
            <p>Quality applications guaranteed to suit your needs</p>
            <ul>
              <li>Database design</li>
              <li>Web interface design</li>
              <li>Desktop applications</li>
              <li>Excel / Pipeline Pilot / Spotfire templates (macros)</li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 my-5">
            <h3 className="mb-3">
              <FontAwesomeIcon
                icon="chart-bar"
                className="fas fa-2x mr-4 text-primary"
              />
              Analytics
            </h3>
            <p>
              Scientific domain knowledge combined with data-mining and analysis
            </p>
            <ul>
              <li>Data mapping and integration</li>
              <li>Text-mining</li>
              <li>Pattern recognition</li>
              <li>Data aggregation and visualizations</li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 my-5">
            <h3 className="mb-3">
              <FontAwesomeIcon
                icon="laptop-code"
                className="fas fa-2x mr-4 text-primary"
              />
              Websites
            </h3>
            <p>Beautiful web designs for today's digital world</p>
            <ul>
              <li>Professional look tailored to specific industry</li>
              <li>Responsive</li>
              <li>User friendly</li>
              <li>SEO friendly</li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-6 my-5">
            <h3 className="mb-3">
              <FontAwesomeIcon
                icon="brain"
                className="fas fa-2x mr-4 text-primary"
              />
              Consulting
            </h3>
            <p>Domain knowledge and technical expertise brought to your team</p>
            <ul>
              <li>Pharmaceutical/Biotech and Healthcare domain knowledge</li>
              <li>Data Architecture</li>
              <li>Cross-platform application development</li>
              <li>Iterative development</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
