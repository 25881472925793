import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "react-block-ui/style.css";
import "./App.css";
import logo from "./olga_logo.svg";
import Header from "./components/header";
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faLaptopCode,
  faBrain,
  faChartBar,
  faCode,
  faPhone,
  faEnvelope,
  faArrowLeft,
  faArrowRight,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";
import { HomePage } from "./pages/home-page";
import { AboutPage } from "./pages/about-page";
import PortfolioPage from "./pages/portfolio-page";
import PortfolioItemPage from "./pages/portfolio-item-page";
import Footer from "./components/footer";
import { ContactPage } from "./pages/contact-page";
import { ServicesPage } from "./pages/services-page";
library.add(
  faLaptopCode,
  faBrain,
  faChartBar,
  faCode,
  faPhone,
  faEnvelope,
  faArrowLeft,
  faArrowRight,
  faBars,
  faLinkedinIn as IconDefinition
);

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Helmet>
            <title>
              Olga Carpenter - Scientist. Informatician. Software Developer
            </title>
            <meta
              name="description"
              content="I combine knowledge of chemistry, biology, statistics and computer
                science to create effective technical solutions for professionals
                in the life science and healthcare industries that save time and
                add value"
            />
          </Helmet>
          <Header />

          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/portfolio" component={PortfolioPage} />
          <Route path="/services" component={ServicesPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path={"/solutions/:title"} component={PortfolioItemPage} />
          <Footer />
        </div>
      </Router>
    );
  }
}

function Topics({ match }: any) {
  return (
    <div>
      <h2>Topics</h2>
      <ul>
        <li>
          <Link to={`${match.url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>
      <div style={{ width: 600 }}>
        <img className="img-fluid" src={logo} alt="" />
      </div>
      <Route path={`${match.path}/:topicId`} component={Topic} />
      <Route
        exact
        path={match.path}
        render={() => <h3>Please select a topic.</h3>}
      />
    </div>
  );
}

function Topic({ match }: any) {
  return (
    <div>
      <h3>{match.params.topicId}</h3>
    </div>
  );
}

export default App;
