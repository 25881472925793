import * as React from "react";

export interface IAboutProps {}

export function AboutSection(props: IAboutProps) {
  return (
    <section className="bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="section-heading text-white">
              Solutions tailored to Your Needs!
            </h2>
            <hr className="light my-4" />
            <p className="text-faded lead mb-4">
              I combine knowledge of chemistry, biology, statistics and computer
              science to create effective technical solutions for professionals
              in the life science and healthcare industries that save time and
              add value
            </p>
            <a href="/contact" className="btn btn-light btn-xl">
              Get Started!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
