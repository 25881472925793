export enum SolutionType {
  Web = "Web",
  Desktop = "Desktop",
  Analysis = "Analysis",
  Website = "Business Website",
  ETL = "ETL"
}

export interface IPortfolioItem {
  image: string;
  title: string; // short title
  headline?: string;
  description: string;
  results: string;
  type: SolutionType[];
  tags: string[];
  features?: string[];
}

export const PortfolioItems: IPortfolioItem[] = [
  {
    image: "dmpk.jpg",
    title: "IVIVe Automation",
    headline:
      "Spotfire Tool to predict compounds’ human invivo hepatic clearance from invitro measurements",
    description:
      "Part of the drug development process involves screening compounds for activity and ADME metrics.  Scientists run invitro experiments in different species and use invitro invivo extrapolation (ivive) to predict how well it will perform in humans.  This was a process of gathering data, formatting it, adding it to the corresponding excel template for the specific species and matrix to perform the needed calculations and analysis.  This process took about 10 hours to do for the average scientist. ",
    results:
      "This tool, created in TIBCO Spotfire, automates the process for ivive and decreased the analysis time from 10 hours to 10 minutes!  There is no longer a need to maintain multiple excel files across the organization and a standard method is used for IVIVe analysis.  This increased efficiency allowed users to conduct this analysis more often, giving them more information for better decision making.  Moreover, the standard workflow across global sites allows for better communication and data integration.",
    type: [SolutionType.Analysis, SolutionType.ETL],
    tags: ["DMPK", "IVIVe", "TIBCO Spotfire", "Automation", "IronPython"],
    features: [
      "Standard global process for IVIVe",
      "Suitable for any species-strain-matrix combination",
      "Stores regression datasets (training data) for comparison",
      "Automatically fetches relevant data from company database and performs IVIVe calculations",
      "Allows flexibility to mix-and-match data from different species when matching one is not available",
      "Interactive visualizations for drilldown/detail view",
      "Available on the web through Spotfire web player"
    ]
  },
  {
    image: "analysis-paperwork.jpg",
    title: "NONMEM Dataset Request Tool",
    description: `SAS programmers in Clinical Pharmacology groups of pharmaceutical companies get multiple requests from pharmacometricians to build Nonmem datasets.  These datasets are then used to conduct analyses which provide critical information drug development decision making and for preparing FDA submissions and Regulatory responses.  
    In a global environment, it is difficult to keep track of requests and ensure FDA standards and consistent specifications are used when each pharmacometrician is using their own standards and ways of working.  The desired solution would streamline the process of ordering datasets from the statistics group, establish consistent specifications and SAS data structure standards across the organization, as well as keep track of versions and changes to each order form.
    `,
    results:
      "A web-based order form accessible globally for pharmacometricians to select specifications for nonmem datasets.  The tool stores and keeps track of order changes as well as provide a draft NonMem data specification that SAS programmers will use to derive the NonMem dataset.  This standard workflow eliminates inefficiencies due to lack of data standards, inconsistent data specifications and inconsistent data warehousing, which increases the timeliness, quality, and reproducibility of pharmacometric analyses.",
    type: [SolutionType.Web],
    tags: ["SAS", "AngularJs", "REST APIs", "Oracle", "Clinical Pharmacology"],
    features: [
      "Standard global process for requesting NONMEM datasets",
      "Stores dataset orders",
      "Tracks changes to orders",
      "Stores default standards for NONMEM variables",
      "Allows adding, deleting and re-ordering of variables",
      "Outputs starter sas template for programmers",
      "Accessible globally on the web"
    ]
  },
  {
    image: "microplate.jpg",
    title: "FIC Calculator",
    description:
      "Many microplate experiments are conducted to evaluate invitro synergy for multiple drug combinations.  These experiments involve measuring bacterial growth at various concentrations of the substance(s) in question to determine the minimum concentration required to inhibit growth (MIC) as well as the presence of any synergistic combinations for growth inhibition, which is determined by the fractional inhibitory concentration (FIC) index.  Analysis for these experiments can be a challenge for most scientists as the data can be great.  A tool to automate this process and cut down analysis time was desired.",
    results:
      "A windows application to parse multiple formats of raw plate readers, configuration of experiment layout (initial concentration, dilution factor, controls location, drug names, etc.), and outputs MIC and FIC results as excel file. The tool developed provides data analysis relief by automating the checkerboard analysis process.  Complete analysis of 20 plate experiment can be done in 5 minutes, significantly reducing analysis time.",
    type: [SolutionType.Analysis, SolutionType.ETL, SolutionType.Desktop],
    tags: [
      "FIC",
      "Checkerboard Analysis",
      "C#.Net",
      "VBA",
      "MIcrobiology",
      "Antibiotics",
      "Automation"
    ],
    features: [
      "Parses multiple formats of Microplate Reader data",
      "Allows multiple configurations for plate layout",
      "Calculates compound concentration based on starting concentration and dilution factor",
      "Calculates MIC for each compound and FIC for each combination",
      "Outputs excel template with visual interpretation of each plate and summary data"
    ]
  },
  {
    image: "science-trends.jpg",
    title: "Hot Science",
    description:
      "In order to determine best areas for business growth, it is important to identify where the biggest needs and opportunities are.  With so much data to consider, the goal was to use informatics to quickly highlight areas of interest that can be further reviewed by drug development experts and business strategists.",
    results:
      "A prototype process for data mining large amounts of scientific documents for disease/target terms using Pipeline Pilot and SciBite’s text-mining system, Termite.  A “hotness” algorithm was developed to determine each area’s activity trend.  TIBCO Spotfire was then used for further analysis and exploration of data and results.  This information was then used for exploring critical areas for scientific advancement and strategic business direction.",
    type: [SolutionType.Analysis, SolutionType.ETL],
    tags: [
      "Science trends",
      "text-mining",
      "Pipeline Pilot",
      "TIBCO Spotfire",
      "SciBite Termite",
      "Algorithm development",
      "Automation"
    ],
    features: [
      "automatically fetches documentation data for set date range",
      "identifies biomedical keywords via MeSH terms",
      "Calculates correlation of terms via Fisher's Exact test",
      "Calculates 'hotness' score based on weights provided by user through Spotfire input form",
      "Drilldown visualizations in Spotfire"
    ]
  },
  {
    image: "pharmaceuticals.jpg",
    title: "Pharmaceutical Consulting Website",
    headline:
      "A Pharma/biotech industry-related, professional and clean design in Wordpress for easy navigation and lead-generation for consulting professional",
    description:
      "For a global business, an online presence is essential for effective marketing and lead generation.  A business website that can properly portray a company's brand, services and products is needed to reach global clients.",
    results:
      "A performant, Wordpress website was created with a clean, professional look to market a Pharmaceutical/Biotech Consultant's services to his global clients.  This included	responsive design for viewing content across various devices (phone, tablet, desktop), industry-relevant images and custom formatting for responsive layout, and SEO-compliant page configuration.",
    type: [SolutionType.Website, SolutionType.Web],
    tags: [
      "Wordpress",
      "Responsive design",
      "Drug Development",
      "Regulatory Compliance",
      "Web Design"
    ],
    features: [
      "Responsive layout",
      "Pharma/Biotech-relevant images",
      "Clean, professional look",
      "Easily modifiable through admin panel",
      "SSL configured"
    ]
  },
  {
    image: "pharma-manufacturing.jpg",
    title: "Drug Manufacturing Database",
    headline:
      "An MS Access database for tracking manufacturing of clinical trial drug parameters, manufacturing sites, clinics, dose orders, and more.",
    description:
      "Administration of a radioactive imaging agent with a 2hr half-life required same-day preparation (manufacturing).  This lead to many lots being prepared for a Phase I Clinical Trial.  In order to identify possible issues and optmize the process, it is imperative to properly document execution of manufacturing protocol and resulting lot parameters in order to allow for proper analysis of the data.",
    results:
      "A secure, MS Access database was created to keep track of lots manufactured at different sites and the doses administered.  This allowed formulation scientists to easily query the data and perform analysis to identify trends.  The application also allowed for business leaders to quickly run reports on Clinical Trial performance.",
    type: [SolutionType.Desktop, SolutionType.ETL, SolutionType.Analysis],
    tags: [
      "MS Access",
      "Reporting",
      "Drug Development",
      "Drug Manufacturing",
      "Clinical Trials",
      "Database Design"
    ],
    features: [
      "Provides data-entry forms",
      "Stores manufacturing data from clinical batch records",
      "Tracks changes",
      "Provides secure access to relevant business users for data analysis",
      "Provides standard reporting for business executives"
    ]
  }
];
