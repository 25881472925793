import * as React from "react";
import logo from "./../images/olga-carpenter.jpg";
import { PageHeader } from "../components/page-header";
import Card from "reactstrap/lib/Card";
import CardImg from "reactstrap/lib/CardImg";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardText from "reactstrap/lib/CardText";
import Button from "reactstrap/lib/Button";
import { ContactSection } from "../components/contact-section";

export interface IAboutProps {}

export function AboutPage(props: IAboutProps) {
  return (
    <div className="App-page">
      <PageHeader title="About" />
      <div className="container page-content">
        <div className="row">
          <div className="col-lg-4">
            <Card>
              <CardImg top width="100%" src={logo} alt="Olga Carpenter" />
              <CardBody>
                <CardTitle>Olga Carpenter</CardTitle>
                <CardSubtitle>
                  Scientist, Informatician, Software Engineer
                </CardSubtitle>

                <CardText className="mt-4">
                  <strong>M.S. Bioinformatics</strong>
                  <br />
                  San Diego State University
                </CardText>
                <CardText>
                  <strong>B.S. Chemistry</strong>
                  <br />
                  University of Massachusetts Amherst
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-8 my-4">
            <h2 className="text-center">A passion for problem-solving</h2>
            <p className="lead">
              Nothing gives me more satisfaction than solving challenging
              problems and seeing the effect of my solutions on users'
              productivity and business goals. My background in the life
              sciences helps me understand the needs of scientists when it comes
              to analyzing the huge amounts of data collected in the lab as well
              as creating useful reports to present results and make business
              decisions. This understanding allows me to provide highly
              effective technology solutions.
            </p>
            <h2 className="text-center">Results-Driven</h2>
            <p className="lead">
              I have a high level of customer satisfaction in developing
              applications that allow easy access to data/services and make work
              more efficient for users by automating tedious tasks. I work
              closely with users to understand their needs and keep open
              communication throughout the development process to obtain
              feedback and end with the best solution possible.
            </p>
            <h2 className="text-center">
              Wide range of Experience and Technical Skills
            </h2>
            <p className="lead">
              My experience ranges from full-stack web-development, data
              management and analysis, database design, text-mining, data
              mining, pattern recognition, synthetic chemistry, analytical
              chemistry, development manufacturing, and formulation development.
              I have also worked and am familiar with regulations pertaining to
              the pharmaceutical industry including cGMP, GLP, GCP and ICH
              guidelines.
            </p>
          </div>
        </div>
        <ContactSection />
      </div>
    </div>
  );
}
