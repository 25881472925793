import * as React from "react";
import { IPortfolioItem, PortfolioItems } from "../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

export default class PortfolioItemPage extends React.Component<{ match: any }> {
  private item: IPortfolioItem;
  private img: any;
  constructor(props: { match: any }) {
    super(props);

    const { title } = props.match.params;
    this.item =
      PortfolioItems.find(item => item.title === title.replace(/-/g, " ")) ||
      PortfolioItems[0];
    this.img = require(`../images/portfolio/${this.item.image}`);
  }

  render() {
    const { item, img } = this;
    return (
      <div className="container-fluid App-page">
        <Helmet>
          <title>{item.title}</title>
          <meta name="description" content={item.description} />
        </Helmet>
        <div className="container portfolio-item">
          <div className="item-section">
            <h1>{item.title}</h1>
            <p className="lead">{item.headline}</p>
            <div className="mt-2">
              <a className="btn btn-primary sr-button" href="/portfolio">
                <FontAwesomeIcon
                  icon="arrow-left"
                  className="fas mr-2 text-white"
                />
                View More
              </a>
            </div>
          </div>
          <div className="my-4">
            <img src={img} alt={item.title} className="img-fluid" />
          </div>
          <div className="item-section">
            <h2>Overview</h2>
            <p>{item.description}</p>
          </div>
          <div className="item-section">
            <h2>Results</h2>
            <p>{item.results}</p>
            {item.features && item.features.length && (
              <React.Fragment>
                <h4>Features</h4>
                <ul>
                  {item.features.map(f => (
                    <li>{f}</li>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </div>
          <div className="item-section mb-5">
            <h2>Tags</h2>
            <div>
              {item.tags.map(t => (
                <span className="tag">{t}</span>
              ))}
            </div>
          </div>
        </div>
        <section className="bg-light">
          <div className="container text-center">
            <h2 className="mb-4">Need a solution like this?</h2>
            <a className="btn btn-primary btn-xl sr-button" href="/contact">
              Let's Talk!
              <FontAwesomeIcon
                icon="arrow-right"
                className="fas ml-2 text-white"
              />
            </a>
          </div>
        </section>
      </div>
    );
  }
}
