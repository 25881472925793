import * as React from "react";
import { PageHeader } from "../components/page-header";
import ContactForm from "../components/contact-form";
import { Helmet } from "react-helmet";

export interface IContactPageProps {}

export function ContactPage(props: IContactPageProps) {
  const instruction = `Tell me about your project and I will be in touch to discuss
    solutions...`;
  return (
    <div className="App-page">
      <Helmet>
        <title>Olga Carpenter - Contact - Let's Work Together!</title>
        <meta
          name="description"
          content="Tell me about your project and I will be in touch to discuss
    solutions..."
        />
      </Helmet>
      <PageHeader title="Let's Work Together" />
      <div className="container page-content">
        <ContactForm instruction={instruction} />
      </div>
    </div>
  );
}
