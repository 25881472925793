import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import logo from "./../images/olga-light.png";

interface IState {
  isOpen: boolean;
}

export default class Header extends React.Component<{}, IState> {
  constructor(props: {}, public state: IState) {
    super(props);

    state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <header className="App-header">
        <Navbar expand="md" color="dark" className="navbar-dark">
          <div className="container d-flex flex-nowrap">
            <NavbarBrand href="/" className="flex-shrink-1">
              <img src={logo} alt="Logo" className="App-logo img-fluid" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="fas fa-bars" />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/services/">Services</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/portfolio">Solutions</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/about">About</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/contact">Contact</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </header>
    );
  }
}
