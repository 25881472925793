import * as React from "react";

export interface IPageHeaderProps {
  title: string;
}

export function PageHeader(props: IPageHeaderProps) {
  return (
    <div className="bg-dark">
      <div className="container">
        <div className="row no-gutters" style={{ padding: "4rem 0" }}>
          <div className="col-lg-8">
            <h1 className="page-title text-white pl-2">{props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
