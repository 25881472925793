import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {}

export const ServicesSection = (props: IProps) => {
  return (
    <section className="services-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">At Your Service</h2>
            <hr className="my-4" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <FontAwesomeIcon
                icon="code"
                className="fas fa-4x mb-3 text-primary"
              />
              <h3 className="mb-3">Development</h3>
              <p className="text-muted mb-0">
                Quality applications guaranteed to suit your needs
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <FontAwesomeIcon
                icon="chart-bar"
                className="fas fa-4x mb-3 text-primary"
              />
              <h3 className="mb-3">Analytics</h3>
              <p className="text-muted mb-0">
                Scientific domain knowledge combined with data-mining and
                analysis
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <FontAwesomeIcon
                icon="laptop-code"
                className="fas fa-4x mb-3 text-primary"
              />
              <h3 className="mb-3">Websites</h3>
              <p className="text-muted mb-0">
                Beautiful, responsive web designs tailored to your industry
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="service-box mt-5 mx-auto">
              <FontAwesomeIcon
                icon="brain"
                className="fas fa-4x mb-3 text-primary"
              />
              <h3 className="mb-3">Consulting</h3>
              <p className="text-muted mb-0">
                Domain knowledge and technical expertise brought to your team
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
