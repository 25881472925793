import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "./contact-form";

interface IProps {}

export function ContactSection(props: IProps) {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="section-heading">Let's Work Together!</h2>
            <hr className="my-4" />
            <p className="mb-5">
              Ready to start your next project? That's great! Send me a message
              and I will get back to you as soon as possible!
            </p>
          </div>
        </div>
        <div className="row mx-auto">
          <ContactForm />
        </div>
      </div>
    </section>
  );
}
